import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-rightbar',
  templateUrl: './login-rightbar.component.html',
  styleUrls: ['./login-rightbar.component.css']
})
export class LoginRightbarComponent implements OnInit {
  currentDate: Date = new Date();
 

  constructor(
   
) { }

  ngOnInit() {

      
  }

  
 


}
