import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface AlertModel {
  title: string;
  message: string;
 
}

@Component({
  selector: 'alert',

  template: `
    <div class="modal-content">
      <div class="modal-header">
        <div class="col-sm-6">
          <h4>{{title || 'Alert!'}}</h4>
        </div>
        <div class="col-sm-6">
        <button type="button" class="close" data-dismiss="modal" (click)="close()" style="float:right;text-align:right;">&times;</button>
        </div>
      </div>
      <div class="modal-body">
        <p style="white-space: pre-wrap;">{{message || 'TADAA-AM!'}}
        </p>
      </div>
      <div class="modal-footer">
      <div class="col-sm-10">
      </div>
         
      <div class="col-sm-2">
      <button type="button" class="btn btn-danger" (click)="close()">Close</button>
      </div>
      </div>
    </div>
  `
})
export class AlertComponent extends SimpleModalComponent<AlertModel, null> implements AlertModel {
  title: string;
  message: string;
  
  constructor() {
    super();
  }
}
