import { Component, OnInit } from '@angular/core';
import { LawyerinfoService } from '../../lawyerinfo.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { JwtService } from '../../jwt.service';
import { AppComponent } from '../../app.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { AlertService } from '../../alert-message/alert.service';
//import { LeftsidebarComponent } from '../../leftsidebar/leftsidebar.component';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { AlertComponent } from 'src/app/alert/alert.component';

const endpoint = "https://admin.bdlawservice.com/public/api/";

@Component({
  selector: 'app-homecontent',
  templateUrl: './logincontent.component.html',
  styleUrls: ['./logincontent.component.css']
})
export class LogincontentComponent implements OnInit {

  scrollnews: any = [];
  scrollnewsdetails: any = [];
  errorMessage: string;
  errorMessagelcms: string;
  isLoadingImage: boolean = false;
  userdata:any = [];
  lcmsuserdata:any = [];
  lawyerinfodata:any = [];
  username: any;
  password: any;
  lawyerCode: any;
  mobile: any;
  
  isLoading: boolean = false;
  ismodal:boolean = false;
  ismodallcms:boolean = false;
  isLoadinglcms: boolean = false;
  is_loggedin: boolean = false;
  is_lcms_loggedin: boolean = false;
  is_loggedout: boolean = true;
  isLogin: boolean = false;
  isLogout: boolean = true;
  current_laywerCode: string;
  rememberMe: boolean = false;
  lcmsrememberMe: boolean = false;


  totalnotification:any = [];



  constructor(
    public lawyerinfo_api: LawyerinfoService,
    private route: ActivatedRoute,
    private router: Router,
    public rest:JwtService,  
    public app:AppComponent,
    //public leftber:LeftsidebarComponent,
    private activatedRoute: ActivatedRoute,
    private SimpleModalService: SimpleModalService,
    public sport: AlertService

   
   
  ) { }

 // const webdriver = require('selenium-webdriver');

  // Input capabilities
  openNewTab (){
    var newTabWindow = open();
    return newTabWindow;
}

updateTabLocation(tabLocation, tab) {
    if(!tabLocation){
      tab.close();
    }
    tab.location.href = tabLocation;
}


  ngAfterViewInit(){
  //  this.leftber.gettodayonlinevisitor();
  //console.log(222);
  //  this.leftber.gettodaytotalvisitor();
   this.app.getDeviceInformation();

   setTimeout(()=>this.app.gescrollnews(), 2000);

    ;
   // this.gebodynews();
    // this.gebodynewsdetails();
    // this.current_laywerCode = this.app.getLawyerCode();    
    
    // if(this.current_laywerCode != null && this.current_laywerCode != "sadmin"){
    //   this.getLawyerInfo(this.current_laywerCode);
    //   this.gettotalnotification();
    // }

    let rememberMe = localStorage.getItem('rememberMe');
    if(rememberMe=='yes'){
     
      this.rememberMe=true;
      this.username = localStorage.getItem('rememberMeuser');
      this.password = localStorage.getItem('rememberMepass');
    }else{
      this.rememberMe=false;
     
    }

    let lcmsrememberMe = localStorage.getItem('lcmsrememberMe');
    if(lcmsrememberMe=='yes'){
     
      this.lcmsrememberMe=true;
      this.lawyerCode = localStorage.getItem('lcmsrememberMeuser');
      this.mobile = localStorage.getItem('lcmsrememberMepass');
    }else{
      this.lcmsrememberMe=false;
     
    }

  }
  ngOnInit() {
    
   
    //console.log(111);
   

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
       
        if(this.app.loggedIn()){         
          this.is_loggedin = true;         
        } else {
          this.is_loggedin = false;
        }
      }
    });

    if(this.app.loggedIn()){         
      this.is_loggedin = true;         
    } else {
      this.is_loggedin = false;
    }
  }
//newcode
actionLogin() {
 // this.showAlerts();
if(this.app.loggedIn()){
  //alert("You are already login");
  var url = "https://bdlawservice.net/home";
       
                var win= window.open(url, "_blank");
              
                 if (win) {
                   //Browser has allowed it to be opened
                  
                   win.focus();
               } else {
                  
                   this.ismodal = true;
                 
                   setTimeout(()=>this.nextModal(), 1);
                  setTimeout(()=> location.reload(), 10);
              
               }

}
else{

  let postData = {
    username: this.username,
    name: this.password
  }
  //console.log(postData);
  
  this.isLoading = true;
  

  this.rest
      .login(this.username, this.password)
      .subscribe(
        userdata => {
         // console.log(userdata);
            this.userdata = userdata;
            this.lawyerinfodata = '';
            this.isLogin = false;
            this.isLogout = true;
            this.is_loggedin = true;
            this.is_loggedout = false;
            this.isLoading = false;
            this.errorMessage = '';  
            
            if(this.rememberMe){
              localStorage.setItem('rememberMe', 'yes');
              localStorage.setItem('rememberMeuser',  this.username);
              localStorage.setItem('rememberMepass',  this.password);
             
            }   
            else{
              localStorage.removeItem("rememberMe");
              localStorage.removeItem("rememberMeuser");
              localStorage.removeItem("rememberMepass");
              this.username = '';
              this.password = '';
            }      
           
            var url = "https://bdlawservice.net/home";

            window.open(url, "_blank");
       
                var win= window.open(url, "_blank");
               win.focus();
                 if (win) {
                   //Browser has allowed it to be opened
                  
                   win.focus();
               } else {
                  
                   this.ismodal = true;
                 
                   setTimeout(()=>this.nextModal(), 1);
                  setTimeout(()=> location.reload(), 10);
              
               }
          
         // location.reload();
        // setTimeout(()=> location.reload(), 1000);
            
          },
          error => {
              this.errorMessage = <any>error
              this.isLogin = true
              this.isLogout = false
              this.is_loggedin = false;
              this.is_loggedout = true;
              this.isLoading = false;
              this.username = '';
              this.password = '';
          }
      );

        }
    
}

nextModal(){
 // alert("kkdjknkln")
 // var url = "http://localhost:4200/";
  //window.open(url, "_blank");
  let element:HTMLElement = document.getElementById('auto_triggers1') as HTMLElement; 
  element.click();

  this.ismodal = false;

}

nextModallcms(){
  let element:HTMLElement = document.getElementById('auto_triggerslcms') as HTMLElement; 
  element.click();
  this.ismodallcms = false;

}

showAlerts() {
  

  this.SimpleModalService.addModal(AlertComponent, { title: 'Please disale firefox popup prevantion', message: 'For avoid this massage Please click Option button and Select Allows Pop-ups for new.bdlawservice.com Or click Nextpage Button ' });
}
closeModal(){
  this.ismodal = false;
  this.ismodallcms = false;
}
actionlcmsLogin() {
  let postData = {
    lawyerCode: this.lawyerCode   
   
  }
  //console.log(postData);

  this.isLoadinglcms = true;
 //window.location.href='https://lcmsbd.com/'; 
  this.lawyerinfo_api
      .lcmslogin(postData)
      .subscribe(
        lcmsuserdata => {
         // console.log(lcmsuserdata);
            this.lcmsuserdata = lcmsuserdata;
        //alert(Number(this.mobile));
            this.isLoadinglcms = false;
         if(this.lawyerCode===lcmsuserdata.lawyer_id && Number(this.mobile)===Number(lcmsuserdata.mobile)){
          this.is_lcms_loggedin = true;
         
          let url='https://new.bdlawreference.com/Common/dokarindexlawbddibinak';
          var win= window.open(url, "_blank");
              
          if (win) {
            //Browser has allowed it to be opened
            win.focus();
        } else {
           
            this.ismodallcms = true;
          
            setTimeout(()=>this.nextModallcms(), 1);
            setTimeout(()=> location.reload(), 10);
       
        }

          //window.location.href='https://new.bdlawreference.com/Common/dokarindexlawbddibinak'  
         // window.open("https://new.bdlawreference.com/Common/dokarindexlawbddibinak", "_blank");
          this.errorMessagelcms='';
        
        if(this.lcmsrememberMe){
          localStorage.setItem('lcmsrememberMe', 'yes');
          localStorage.setItem('lcmsrememberMeuser',   this.lawyerCode);
          localStorage.setItem('lcmsrememberMepass',   this.mobile);
         
        }   
        else{
          localStorage.removeItem("lcmsrememberMe");
          localStorage.removeItem("lcmsrememberMeuser");
          localStorage.removeItem("lcmsrememberMepass");
          this.lawyerCode ='';
          this.mobile='';
        }      
         
         }
         else{
          this.errorMessagelcms="Search List Code No Or Search List Mobile No not Match";
          this.lawyerCode ='';
          this.mobile='';
         }
        // this.is_lcms_loggedin = true;
       
           // location.reload(); 
          // setTimeout(()=> location.reload(), 1000);
            
          },
          error => {
              this.errorMessagelcms = <any>error
            //  window.location.href='https://lcmsbd.com/';
              this.isLoadinglcms = false;
              this.lawyerCode ='';
              this.mobile='';
          }
      );
  
  
    
}




actionLogout() {

  this.isLoading = true;

  this.rest.logout();

  this.isLogin = true
  this.isLogout = false
  this.is_loggedin = false;
  this.is_loggedout = true;
  this.isLoading = false;
  this.router.navigateByUrl('/');
 // location.reload();

}

getLawyerInfo(lawyerCode) {

  let postData = {
    lawyerCode: lawyerCode
  }
  
  this.lawyerinfo_api
          .getLawyerinfo(postData)
          .subscribe(
              lawyerinfodata => {
                  this.lawyerinfodata = lawyerinfodata
              }
          );
}


gettotalnotification() {
  let postData = {
    current_laywerCode:  this.current_laywerCode,
  }
  //console.log(this.current_laywerCode);

  this.lawyerinfo_api
      .gettotalnotification(postData)
      .subscribe(
        totalnotification => {
            this.totalnotification = totalnotification
          },
          error => {
              this.errorMessage = <any>error
          }
      );
}

/////////////
  gebodynews() {

    let postData = {
    }
    //console.log(postData);

    this.lawyerinfo_api
        .gebodynews(postData)
        .subscribe(
          scrollnews => {
           // console.log(courtwisedata);
              this.scrollnews = scrollnews
            },
            error => {
                this.errorMessage = <any>error
            }
        );
  }

  gebodynewsdetails() {
    this.isLoadingImage = true;
    let postData = {
    }
    //console.log(postData);

    this.lawyerinfo_api
        .gebodynewsdetails(postData)
        .subscribe(
          scrollnewsdetails => {
           // console.log(courtwisedata);
              this.scrollnewsdetails = scrollnewsdetails
              this.isLoadingImage = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoadingImage = false
            }
        );
  }

}
