import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";

//const endpoint = 'http://localhost:808/api/';

//const endpoint = 'http://192.168.0.106:8000/api/';

//const endpoint = 'http://localhost:8888/bdlawservice_admin/public/api/';

const endpoint = "https://admin.bdlawservice.com/public/api/";


const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class CourtlistService {
  constructor(private http: HttpClient) {}

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getCourtlist(postData): Observable<any> {
    return this.http
      .post(endpoint + "courtlist", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getDraftStatus(postData): Observable<any> {
    if (postData.searchDate2.getFullYear() == new Date().getFullYear()) {
      return this.http
        .post(endpoint + "is_draft_copy", postData, httpOptions)
        .pipe(map(this.extractData));
    }
  }

  getCourtwise(postData): Observable<any> {
    return this.http.post(endpoint + "courtwise", postData, httpOptions);
  }

  getCourtWiseJusticeName(postData): Observable<any> {
    return this.http.post(
      endpoint + "courtwiseJusticeName",
      postData,
      httpOptions
    );
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
