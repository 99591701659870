import { Component, OnInit } from '@angular/core';
import { CauselistsearchService } from '../../../causelistsearch.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cause-list-search-history',
  templateUrl: './cause-list-search-history.component.html',
  styleUrls: ['./cause-list-search-history.component.css']
})
export class CauseListSearchHistoryComponent implements OnInit {

  causelisthistorydata2012:any = [];
  causelisthistorydata2013:any = [];
  causelisthistorydata2014:any = [];
  causelisthistorydata2015:any = [];
  causelisthistorydata2016:any = [];
  causelisthistorydata2017:any = [];
  causelisthistorydata2018:any = [];
  causelisthistorydata2019:any = [];
  causelisthistorydata2020:any = [];
  causelisthistorydata2021:any = [];
  causelisthistorydata2022:any = [];
  causelisthistorydata2023:any = [];
  parties_info_single_data:any = [];
  casetypetitle:any = [];
  
  casetypeid: any;
  caseno: any;
  caseyear: any;
  searching_year_table: any;

  errorMessage: string;
  isLoading: boolean = true;
  isLoadingImg: boolean = true;

  case2012: boolean = false;
  case2013: boolean = false;
  case2014: boolean = false;
  case2015: boolean = false;
  case2016: boolean = false;
  case2017: boolean = false;
  case2018: boolean = false;
  case2019: boolean = false;
  case2020: boolean = false;
  case2021: boolean = false;
  case2022: boolean = false;
  case2023: boolean = false;

  case_type_id: number;
  case_no: number;
  case_year: number;
  private sub: any;

  constructor(
    public rest:CauselistsearchService, 
    private route: ActivatedRoute, 
    private router: Router
) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.case_type_id = +params['case_type_id'];
      this.case_no = +params['case_no'];
      this.case_year = +params['case_year'];
    });

    this.getCauselisthistory();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getCauselisthistory() {
    let postData = {
      casetypeid: this.case_type_id,
      caseno: this.case_no,
      caseyear: this.case_year,
      searching_year_table: this.case_year,
    }
    //console.log(postData);

    var items: number[] = [];
    for(var i = postData.searching_year_table; i <= (new Date()).getFullYear(); i++){
      items.push(i);
    }
    
    this.rest
        .getLastPartiesInfoForHistory(postData)
        .subscribe(
          parties_info_single_data => {
              this.parties_info_single_data = parties_info_single_data
            },
            error => {
                this.errorMessage = <any>error
            } 
    );

    if(items.indexOf(2012) > -1){

      //console.log('2012 te dhukse');

      postData.searching_year_table = 2012;

      this.rest
        .getCauselisthistory(postData)
        .subscribe(
          causelisthistorydata2012 => {
              this.causelisthistorydata2012 = causelisthistorydata2012
              this.isLoading = false
              this.isLoadingImg = false
              this.case2012 = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImg = false
                this.case2012 = false
            } 
        );
    }

    if(items.indexOf(2013) > -1){

      //console.log('2013 te dhukse');
      postData.searching_year_table = 2013;

      this.rest
        .getCauselisthistory(postData)
        .subscribe(
          causelisthistorydata2013 => {
              this.causelisthistorydata2013 = causelisthistorydata2013
              this.isLoading = false
              this.isLoadingImg = false
              this.case2013 = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImg = false
                this.case2013 = false
            } 
        );
    }

    if(items.indexOf(2014) > -1){

      //console.log('2014 te dhukse');
      postData.searching_year_table = 2014;

      this.rest
        .getCauselisthistory(postData)
        .subscribe(
          causelisthistorydata2014 => {
              this.causelisthistorydata2014 = causelisthistorydata2014
              this.isLoading = false
              this.isLoadingImg = false
              this.case2014 = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImg = false
                this.case2014 = false
            } 
        );
    }

    if(items.indexOf(2015) > -1){

      //console.log('2015 te dhukse');
      postData.searching_year_table = 2015;

      this.rest
        .getCauselisthistory(postData)
        .subscribe(
          causelisthistorydata2015 => {
              this.causelisthistorydata2015 = causelisthistorydata2015
              this.isLoading = false
              this.isLoadingImg = false
              this.case2015 = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImg = false
                this.case2015 = false
            } 
        );
    }

    if(items.indexOf(2016) > -1){

      //console.log('2016 te dhukse');
      postData.searching_year_table = 2016;

      this.rest
        .getCauselisthistory(postData)
        .subscribe(
          causelisthistorydata2016 => {
              this.causelisthistorydata2016 = causelisthistorydata2016
              this.isLoading = false
              this.isLoadingImg = false
              this.case2016 = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImg = false
                this.case2016 = false
            } 
        );
    }

    if(items.indexOf(2017) > -1){

      //console.log('2017 te dhukse');
      postData.searching_year_table = 2017;

      this.rest
        .getCauselisthistory(postData)
        .subscribe(
          causelisthistorydata2017 => {
              this.causelisthistorydata2017 = causelisthistorydata2017
              this.isLoading = false
              this.isLoadingImg = false
              this.case2017 = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImg = false
                this.case2017 = false
            } 
        );
    }

    if(items.indexOf(2018) > -1){

      //console.log('2018 te dhukse');
      postData.searching_year_table = 2018;

      this.rest
        .getCauselisthistory(postData)
        .subscribe(
          causelisthistorydata2018 => {
              this.causelisthistorydata2018 = causelisthistorydata2018
              this.isLoading = false
              this.isLoadingImg = false
              this.case2018 = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImg = false
                this.case2018 = false
            } 
        );
    }

    

    if(items.indexOf(2019) > -1){

      postData.searching_year_table = 2019;

      this.rest
        .getCauselisthistory(postData)
        .subscribe(
          causelisthistorydata2019 => {
              this.causelisthistorydata2019 = causelisthistorydata2019
              this.isLoading = false
              this.isLoadingImg = false
              this.case2019 = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImg = false
                this.case2019 = false
            } 
        );
    }



    if(items.indexOf(2020) > -1){

      postData.searching_year_table = 2020;

      this.rest
        .getCauselisthistory(postData)
        .subscribe(
          causelisthistorydata2020 => {
              this.causelisthistorydata2020 = causelisthistorydata2020
              this.isLoading = false
              this.isLoadingImg = false
              this.case2020 = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImg = false
                this.case2020 = false
            } 
        );
    }

    if(items.indexOf(2021) > -1){

      postData.searching_year_table = 2021;

      this.rest
        .getCauselisthistory(postData)
        .subscribe(
          causelisthistorydata2021 => {
              this.causelisthistorydata2021 = causelisthistorydata2021
              this.isLoading = false
              this.isLoadingImg = false
              this.case2021 = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImg = false
                this.case2021 = false
                
            } 
        );
    }



    if(items.indexOf(2022) > -1){

      postData.searching_year_table = 2022;

      this.rest
        .getCauselisthistory(postData)
        .subscribe(
          causelisthistorydata2022 => {
              this.causelisthistorydata2022 = causelisthistorydata2022
              this.isLoading = false
              this.isLoadingImg = false
              this.case2022 = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImg = false
                this.case2022 = false
                
            } 
        );
    }
    

    if(items.indexOf(2023) > -1){


      //console.log('2023 te ');

      postData.searching_year_table = 2023;

      
      //console.log(postData);

      this.rest
        .getCauselisthistory(postData)
        .subscribe(
          causelisthistorydata2023 => {
              this.causelisthistorydata2023 = causelisthistorydata2023
              this.isLoading = false
              this.isLoadingImg = false
              this.case2023 = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImg = false
                this.case2023 = false
                
            } 
        );

        
        
    }
    //console.log("case_year: "+this.case_year+" items "+items);


    this.rest
        .getCasetypeTitle(this.case_type_id)
        .subscribe(
          casetypetitle => {
              this.casetypetitle = casetypetitle
              this.isLoading = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }

}
