import { Component, OnInit } from '@angular/core';
import { LawyerinfoService } from '../../lawyerinfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtService } from '../../jwt.service';
import { AppComponent } from '../../app.component';



import { isNull } from '@angular/compiler/src/output/output_ast';

const endpoint = "https://admin.bdlawservice.com/public/api/";

@Component({
  selector: 'app-homecontent',
  templateUrl: './homecontent.component.html',
  styleUrls: ['./homecontent.component.css']
})
export class HomecontentComponent implements OnInit {

  scrollnews: any = [];
  scrollnewsdetails: any = [];
  errorMessage: string;
  errorMessagelcms: string;
  isLoadingImage: boolean = false;
  userdata:any = [];
  lcmsuserdata:any = [];
  lawyerinfodata:any = [];
  username: any;
  password: any;
  lawyerCode: any;
  mobile: any;
  
  isLoading: boolean = false;
  isLoadinglcms: boolean = false;
  is_loggedin: boolean = false;
  is_lcms_loggedin: boolean = false;
  is_loggedout: boolean = true;
  isLogin: boolean = false;
  isLogout: boolean = true;
  current_laywerCode: string;


  totalnotification:any = [];


  constructor(
    public lawyerinfo_api: LawyerinfoService,
    private route: ActivatedRoute,
    private router: Router,
    public rest:JwtService,  
    public app:AppComponent,
   
   
  ) { }
  
  ngAfterViewInit(){
  
    this.current_laywerCode = this.app.getLawyerCode();
      
    if(this.current_laywerCode != null && this.current_laywerCode != "sadmin"){
      this.getLawyerInfo(this.current_laywerCode);
      this.gettotalnotification();
    }
  
   
  }

  ngOnInit() {
    this.gebodynews();
    this.gebodynewsdetails();
   
  
    if(this.app.loggedIn()){
      this.is_loggedin = true;
    } else {
      this.is_loggedin = false;
     
    }

  
  }
//newcode
actionLogin() {
  let postData = {
    username: this.username,
    name: this.password
  }
  //console.log(postData);
  
  this.isLoading = true;

  this.rest
      .login(this.username, this.password)
      .subscribe(
        userdata => {
          console.log(userdata);
            this.userdata = userdata;
            this.lawyerinfodata = '';
            this.isLogin = false;
            this.isLogout = true;
            this.is_loggedin = true;
            this.is_loggedout = false;
            this.isLoading = false;
            this.errorMessage = '';
           
            this.username = '';
            this.password = '';

            this.router.navigateByUrl('/home');
         // location.reload();
            
          },
          error => {
              this.errorMessage = <any>error
              this.isLogin = true
              this.isLogout = false
              this.is_loggedin = false;
              this.is_loggedout = true;
              this.isLoading = false;
          }
      );

  
    
}

actionlcmsLogin() {
  let postData = {
    lawyerCode: this.lawyerCode   
   
  }
  //console.log(postData);

  this.isLoadinglcms = true;
 //window.location.href='https://lcmsbd.com/'; 
  this.lawyerinfo_api
      .lcmslogin(postData)
      .subscribe(
        lcmsuserdata => {
         // console.log(lcmsuserdata);
            this.lcmsuserdata = lcmsuserdata;
        //alert(Number(this.mobile));
            this.isLoadinglcms = false;
         if(this.lawyerCode===lcmsuserdata.lawyer_id && Number(this.mobile)===Number(lcmsuserdata.mobile)){
          this.is_lcms_loggedin = true;
          window.location.href='https://new.bdlawreference.com/Common/dokarindexlawbddibinak'  

          this.errorMessagelcms='';
         }
         else{
          this.errorMessagelcms="Search List Code No Or Search List Mobile No not Match";
         }
        // this.is_lcms_loggedin = true;
       
           // location.reload(); 
            
          },
          error => {
              this.errorMessagelcms = <any>error
            //  window.location.href='https://lcmsbd.com/';
              this.isLoadinglcms = false;
          }
      );
  
  
    
}




actionLogout() {

  this.isLoading = true;

  this.rest.logout();

  this.isLogin = true
  this.isLogout = false
  this.is_loggedin = false;
  this.is_loggedout = true;
  this.isLoading = false;
  this.router.navigateByUrl('/');
 // location.reload();

}

getLawyerInfo(lawyerCode) {

  let postData = {
    lawyerCode: lawyerCode
  }
  
  this.lawyerinfo_api
          .getLawyerinfo(postData)
          .subscribe(
              lawyerinfodata => {
                  this.lawyerinfodata = lawyerinfodata
              }
          );
}


gettotalnotification() {
  let postData = {
    current_laywerCode:  this.current_laywerCode,
  }
  //console.log(this.current_laywerCode);

  this.lawyerinfo_api
      .gettotalnotification(postData)
      .subscribe(
        totalnotification => {
            this.totalnotification = totalnotification
          },
          error => {
              this.errorMessage = <any>error
          }
      );
}

/////////////
  gebodynews() {

    let postData = {
    }
    //console.log(postData);

    this.lawyerinfo_api
        .gebodynews(postData)
        .subscribe(
          scrollnews => {
           // console.log(courtwisedata);
              this.scrollnews = scrollnews
            },
            error => {
                this.errorMessage = <any>error
            }
        );
  }

  gebodynewsdetails() {
    this.isLoadingImage = true;
    let postData = {
    }
    //console.log(postData);

    this.lawyerinfo_api
        .gebodynewsdetails(postData)
        .subscribe(
          scrollnewsdetails => {
           // console.log(courtwisedata);
              this.scrollnewsdetails = scrollnewsdetails
              this.isLoadingImage = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoadingImage = false
            }
        );
  }

}
