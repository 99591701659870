import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AlertService } from './alert.service';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['alert-message.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AlertMessageComponent implements OnInit {

    constructor(public sport: AlertService){}


  ngOnInit() {
  }

  

}